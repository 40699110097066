@tailwind base;
@tailwind components;
@tailwind utilities;

.spinner {
  margin: 100px;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

h2 {
  margin-top: 15px;
  font-size: 28px;
  font-weight: 700;
  color: #d9dae9;
}

h3 {
  margin-top: 10px;
  font-size: 23px;
  font-weight: 600;
  color: #d9dae9;
  font-style: italic;
}

h4 {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
  color: #d9dae9;
}

p {
  margin-bottom: 5px;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #dadada;
    color: #1f2023;
  }
}

::-webkit-scrollbar
{
	width: 3px;
	background-color: #898383;
}
::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #7465e6;
}

.bottom-fixed {
  position: fixed;
  bottom: 0,
}